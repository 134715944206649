<!--
* 创建人：罗兴
* 日 期：
* 描 述：假期留校申请
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">假期留校申请审批</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学期:</span>
            <sy-xueqi
              v-model="xqid"
              :selectdqxq="true"
              style="display: inline"
            ></sy-xueqi>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">节假日:</span>
            <el-select
              style="width: 200px"
              v-model="jrid"
              placeholder="请选择假期"
              :clearable="true"
              size="small"
            >
              <el-option
                v-for="item in jrlist"
                :key="item.id"
                :label="item.jqmc"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">系部:</span>
            <el-select
              v-model="bmbh"
              size="small"
              clearable
              @change="bmchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bmlist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px" :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">专业:</span>
            <el-select
              v-model="zybh"
              size="small"
              clearable
              @change="zychange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zylist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">班级:</span>
            <el-select
              v-model="bjbh"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bjlist"
                :key="index"
                :label="item.bjmc"
                :value="item.bjbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学号/姓名:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入学号/姓名"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_sp')"
          icon="el-icon-edit"
          @click="detailInfo"
          >审批</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xueqi" label="学期" width="200">
        </el-table-column>
        <el-table-column prop="jqmc" label="假期名称" width="250">
        </el-table-column>
        <el-table-column prop="bmmc" label="院系" width="200">
        </el-table-column>
        <el-table-column prop="zymc" label="专业" width="200">
        </el-table-column>
        <el-table-column prop="bjmc" label="班级" width="200">
        </el-table-column>
        <el-table-column prop="xh" label="学号" width="200"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="200"> </el-table-column>
        <el-table-column prop="lxdh" label="本人电话" width="150">
        </el-table-column>
        <el-table-column prop="lxsj" label="留校时间" width="200">
          <template slot-scope="scope">
            {{
              dateFormat2(scope.row.kssj) + '-' + dateFormat2(scope.row.jssj)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="fmsfty" label="是否征得父母同意" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.fmsfty === 1" type="success">是</el-tag>
            <el-tag v-if="scope.row.fmsfty === 0" type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="zt" label="审批状态" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.spzt === 0">待辅导员审核</el-tag>
            <el-tag v-if="scope.row.spzt === 100" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 200" type="danger"
              >辅导员审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.spzt === 110" type="success"
              >院系审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 120" type="danger"
              >院系审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.spzt === 111" type="success"
              >系学工审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 112" type="danger"
              >系学工审核拒绝</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
      <el-dialog
        title="留校申请"
        :visible.sync="dialogDetailFormVisible"
        width="40%"
        :close-on-click-modal="false"
        @close="closeDialog"
        top="5vh"
      >
        <el-scrollbar style="height: 700px" class="myscrollbar">
          <el-form
            :model="spinfo"
            v-if="dialogDetailFormVisible"
            ref="formRef"
            :rules="forminfoRules"
          >
            <div class="form-item-group">
              <i class="fa fa-drivers-license-o"></i>
              <div>学生基础信息：</div>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="学号" label-width="120px">
                  <el-input v-model="xsinfo.xh"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="姓名" label-width="120px">
                  <el-input v-model="xsinfo.xm"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="性别" label-width="120px">
                  <el-radio v-model="xsinfo.xbm" label="1">男</el-radio>
                  <el-radio v-model="xsinfo.xbm" label="2">女</el-radio>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="院系" label-width="120px">
                  <el-input v-model="xsinfo.bmmc"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="专业" label-width="120px">
                  <el-input v-model="xsinfo.zymc"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="班级" label-width="120px">
                  <el-input v-model="xsinfo.bjmc"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="form-item-group">
              <i class="fa fa-calendar"></i>
              <div>申请信息</div>
            </div>
            <el-form-item label="现住寝室" label-width="130px" prop="XZQS">
              <el-input
                v-model="info.XZQS"
                autocomplete="off"
                placeholder="现住寝室"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="家庭地址及电话"
              label-width="130px"
              prop="JTDZJDH"
            >
              <el-input
                v-model="info.JTDZJDH"
                autocomplete="off"
                placeholder="家庭地址及电话"
              ></el-input>
            </el-form-item>

            <el-form-item label="个人联系电话" label-width="130px" prop="LXDH">
              <el-input
                v-model="info.LXDH"
                autocomplete="off"
                placeholder="个人联系电话"
              ></el-input>
            </el-form-item>

            <el-form-item label="留校时间" label-width="130px" prop="SJ">
              <el-date-picker
                v-model="info.SJ"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="申请理由" label-width="130px" prop="SQLY">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请输入申请理由"
                v-model="info.SQLY"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="是否征得父母同意"
              label-width="130px"
              prop="FMSFTY"
            >
              <el-radio v-model="info.FMSFTY" :label="1">是</el-radio>
              <el-radio v-model="info.FMSFTY" :label="0">否</el-radio>
            </el-form-item>
            <div class="form-item-group">
              <i class="fa fa-window-restore"></i>
              <div>审批</div>
            </div>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="审批意见" label-width="120px" prop="SPYJ">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    placeholder="请输入内容"
                    v-model="spinfo.SPYJ"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="审批" label-width="120px" prop="SPZT">
                  <el-radio v-model="spinfo.SPZT" :label="1">审批通过</el-radio>
                  <el-radio v-model="spinfo.SPZT" :label="0">审批拒绝</el-radio>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogDetailFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveForm">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import api from '../../api/jqgl'
import { getAuthorizeButtonColumnList, getCurrentXQ } from '../../api/system'
import {
  getStuAllDetailInfo,
  GetListByQX,
  GetZYListByQX,
  GetBJListByQX,
} from '../../api/jcxx'
import moment from 'moment'
export default {
  data() {
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      xqid: null,
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogDetailFormVisible: false, // 是否显示弹出框
      spinfo: {
        SPYJ: '',
        SPZT: 1,
      },
      // 表单验证规则对象
      forminfoRules: {
        SPYJ: [{ required: true, message: '请填写审批意见', trigger: 'blur' }],
        SPZT: [{ required: true, message: '请审批', trigger: 'blur' }],
      },
      xsinfo: {},
      info: {},
      XH: '',
      jrlist: [],
      jrid: '',
      bmlist: [],
      zylist: [],
      bjlist: [], // 班级列表
      bmbh: '',
      zybh: '',
      bjbh: '',
    }
  },
  watch: {
    xqid() {
      const that = this
      api
        .getjjrlist({ queryJson: JSON.stringify({ xqid: that.xqid }) })
        .then(function (res) {
          if (res.code === 200) {
            that.jrid = ''
            that.jrlist = res.data
          }
        })
    },
  },
  components: {
    'sy-xueqi': () => import('../../components/dropdown/dropdown_xueqi'),
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getCurrentXQ().then((res) => {
      if (res.code === 200) {
        this.xqid = res.data.id
        this.getDataPageList()
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
        this.XH = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      api
        .getlxsqsppagelist({
          queryJson: JSON.stringify({
            xqid: this.xqid,
            jrid: this.jrid,
            keyword: this.keyword,
            bmbh: this.bmbh,
            zybh: this.zybh,
            bjbh: this.bjbh,
          }),
          pagination: JSON.stringify(this.pagination),
        })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.info = JSON.parse(JSON.stringify(this.info))
      })
      this.$refs.formRef.resetFields()
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        getStuAllDetailInfo({ XH: this.XH }).then((res) => {
          if (res.code === 200) {
            this.xsinfo = res.data
          }
        })
        api.getlxsqformdata({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.info.ID = res.data.id
            this.info.XZQS = res.data.xzqs
            this.info.JTDZJDH = res.data.jtdzjdh
            this.info.LXDH = res.data.lxdh
            this.info.KSSJ = res.data.kssj
            this.info.JSJ = res.data.jssj
            this.info.SQLY = res.data.sqly
            this.info.FMSFTY = res.data.fmsfty
            this.info.SJ = [new Date(res.data.kssj), new Date(res.data.jssj)]
          }
        })
        this.operation = 0
        this.dialogDetailFormVisible = true
      }
    },
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          api
            .saveSP({
              SQID: this.keyValue,
              SPZT: this.spinfo.SPZT,
              SPYJ: this.spinfo.SPYJ,
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.dialogDetailFormVisible = false
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
        }
      })
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
